import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from '../auth/LogoutButton';
import useAdminAuth from '../../hooks/useAdminAuth';

const Navbar = () => {
  const { isAuthenticated, isAdmin, isLoading } = useAdminAuth();
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = prevScrollPos < currentScrollPos;
      const isScrollingUp = prevScrollPos > currentScrollPos;
      
      // Only hide navbar if we're on mobile
      if (window.innerWidth <= 700) {
        if (isScrollingDown && visible) {
          setVisible(false);
        } else if (isScrollingUp && !visible) {
          setVisible(true);
        }
      } else {
        setVisible(true);
      }
      
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [prevScrollPos, visible]);

  const adminLinks = (
    <ul>
      <li>
        <span className="admin-badge">Admin</span>
      </li>
      <li>
        <Link to="/about">About</Link>
      </li>
      <li>
        <LogoutButton />
      </li>
    </ul>
  );
  
  const guestLinks = (
    <ul>
      <li>
        <Link to="/about">About</Link>
      </li>
      <li>
        <Link to="/login">Login</Link>
      </li>
    </ul>
  );
  
  return (
    <nav className={`navbar bg-dark ${!visible ? 'navbar-hidden' : ''}`}>
      <h1>
        <Link to="/">
          <i className="fas fa-signature"></i> Thankful Wall
        </Link>
      </h1>

      {!isLoading && (
        <Fragment>
          {isAuthenticated ? adminLinks : guestLinks}
        </Fragment>
      )}
    </nav>
  );
};

export default Navbar;
