import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = () => {
  const { logout: auth0Logout } = useAuth0();

  const handleLogout = () => {
    auth0Logout({ 
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  return (
    <button
      className="btn btn-danger"
      onClick={handleLogout}
    >
      Log Out
    </button>
  );
};

export default LogoutButton; 